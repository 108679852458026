html {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 1em; }

body {
  padding: 2em; }

.container {
  max-width: 1440px;
  padding-top: 20px; }

.segment {
  font-size: 80% !important; }

.main-container {
  margin: auto; }

.legend {
  font-size: 80%;
  overflow: hidden; }

.legend > div {
  float: left; }

.legend > div > div {
  margin-bottom: 2px; }

.legend span {
  display: inline-block;
  margin-left: 50px;
  margin-right: 10px;
  border-radius: 4px;
  width: 20px;
  height: 10px; }

.main-navigation {
  margin-bottom: 0 !important; }

.network-loading {
  height: 95vh;
  margin-top: 0 !important; }

.network-header {
  text-align: center; }

.graph-container {
  padding: 0 !important; }

.segment.pushable-sidebar {
  margin-top: 0 !important; }

.network-tools {
  margin-bottom: 0 !important; }

.user-menu p {
  margin: 0 !important; }

.user-menu .user-sign-out {
  cursor: pointer;
  padding-left: 1em; }
  .user-menu .user-sign-out .sign-out-icon {
    margin-left: 0.5em; }

.not-found {
  text-align: center;
  font-size: 1.5rem;
  padding-top: 1em; }
  .not-found .pathname {
    color: green; }

.progress-bar {
  text-shadow: 1px 1px 0px #000; }
